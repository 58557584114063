import { GridIconSetting, UserSettings } from "./../pb-screen/models/application.user.interface";
import { SharedActions } from "./../shared/shared.actions";
import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  Output
} from "@angular/core";
import { environment } from "../../environments/environment";
import { select } from "@angular-redux/store";
import {
  ApplicationUser,
  DocumentToBePrinted
} from "../pb-screen/models/application.user.interface";
import { Observable } from "rxjs";
import { ApplicationUserActions } from "../pb-screen/application.user.actions";
import { settingAction } from "./setting.action";
import { TranslateService } from "@ngx-translate/core";
import { HttpClientService } from "../shared/services/httpclient";
import { ToastrService } from "ngx-toastr";
import { UserSettingsService } from "../pb-screen/services/usersettings.service";
import { GeneralSetting, PlanningSetting, WeekPlanningSetting } from "./setting-modal.interface";
import { IdNameAsStringObject } from "../pb-screen/models/quick-view/id-name-as-string-object.interface";
@Component({
  moduleId: module.id,
  selector: "setting-modal",
  templateUrl: "setting-modal.component.html"
})
export class settingComponent implements OnInit {
  @ViewChild("modal") public modal: ElementRef;
  @Output("companyName") companyName = environment.envName;
  private applicationUser: ApplicationUser;
  @select('planningGroups') public planningGroups$: Observable<IdNameAsStringObject[]>;
  @select('applicationUser') applicationUser$: Observable<ApplicationUser>;
  @select('planningZone') public planningZone$: Observable<any[]>;
  @select('globalSettings') public globalSettings$: Observable<UserSettings[]>;
  public enableAutocomplete: boolean = false;
  fileToUpload: File = null;
  currentGridIcon: GridIconSetting[];
  toIcons: GridIconSetting[];
  activeTab = 1;
  pixelsPerPlanningBlock: number;
  weekPlanningSetting: WeekPlanningSetting = new WeekPlanningSetting();
  planningSetting: PlanningSetting = new PlanningSetting();
  generalSetting: GeneralSetting = new GeneralSetting();
  public planningGroups: IdNameAsStringObject[] = [];
  public planningZones: any[];

  
  PrintedDocumentList: any = [
    { docName: "Transport Order Document" },
    { docName: "Cmr" },
    { docName: "Container Planning List" },
    { docName: "Print Ticket" },
    { docName: "Container Planning List Warehouse" },
    { docName: "Week Planning Report" }
  ];

  selectedScreen: string = "";
  checkedStrings;
  screenList: any = [
    "Depot-In",
    "Depot-Out",
    "PB-Screen",
    "PB-Screen-Details",
    "TO-Screen",
    "TO-Screen-Details",
    "Planning-Board"
  ];

  backgroundImageUrl = environment.hubEndpointAddress + "/App/assets/images/backgrounds/icons/";
  backgrounds = [
    { text: "Container", landingPage: "1" },
    { text: "Short sea shipping", landingPage: "shortsea"},
    { text: "Tank", landingPage: "tank"},
    { text: "Groupage & distribution", landingPage: "groupage_distribution"},
    { text: "Intermodal", landingPage: "intermodal"},
    { text: "International", landingPage: "international"},
    { text: "Custom", landingPage: "custom"}
  ];
  defaultPBGridIcon: GridIconSetting[] = [
    {"id":1,"isVisible":true,"name":"Scanning"},
    {"id":2,"isVisible":true,"name":"Customs"},
    {"id":3,"isVisible":true,"name":"Weighing"},
    {"id":4,"isVisible":true,"name":"ADR"},
    {"id":5,"isVisible":true,"name":"T1"},
    {"id":6,"isVisible":true,"name":"Temperature"},
    {"id":7,"isVisible":true,"name":"High Value"},
    {"id":8,"isVisible":true,"name":"Conflicts"},
    {"id":9,"isVisible":true,"name":"Notifications"},
    {"id":10,"isVisible":true,"name":"Remarks"},
    environment.tarTasEnabled ? {"id":11,"isVisible":true,"name":"Pre-Announcement Status"} : null,
    environment.tarTasEnabled ? {"id":12,"isVisible":true,"name":"Truck Appointment Status"} : null,
    environment.containerReleaseEnabled ? {"id":13,"isVisible":true,"name":"Container Release Status"} : null,
    {"id":14,"isVisible":false,"name":"Documents"}
  ];

  public depotInGridIcons : GridIconSetting[];
  defaultDepotInGridIcons : GridIconSetting[]= [
    {"id":1,"isVisible":true,"name":"Scanning"},
    {"id":2,"isVisible":true,"name":"Customs"},
    {"id":3,"isVisible":true,"name":"Weighing"},
    {"id":4,"isVisible":true,"name":"ADR"},
    {"id":5,"isVisible":true,"name":"T1"},
    {"id":6,"isVisible":true,"name":"Container Pool Status"},
    {"id":7,"isVisible":true,"name":"hasTarTasCode"},
    environment.tarTasEnabled ? {"id":8,"isVisible":true,"name":"Pre-Announcement Status"} : null,
    environment.tarTasEnabled ? {"id":9,"isVisible":true,"name":"Truck Appointment Status"} : null,
    {"id":10,"isVisible":true,"name":"Temperature"},
    {"id":11,"isVisible":true,"name":"High Value"},
    environment.containerReleaseEnabled ? {"id":12,"isVisible":true,"name":"Container Release Status"} : null
  ];

public depotOutGridIcons : GridIconSetting[];
defaultDepotOutGridIcons : GridIconSetting[] = [
    {"id":1,"isVisible":true,"name":"Scanning"},
    {"id":2,"isVisible":true,"name":"Customs"},
    {"id":3,"isVisible":true,"name":"Weighing"},
    {"id":4,"isVisible":true,"name":"ADR"},
    {"id":5,"isVisible":true,"name":"T1"},
    {"id":6,"isVisible":true,"name":"Container Pool Status"},
    {"id":7,"isVisible":true,"name":"hasTarTasCode"},
    environment.tarTasEnabled ? {"id":8,"isVisible":true,"name":"Pre-Announcement Status"} : null,
    environment.tarTasEnabled ? {"id":9,"isVisible":true,"name":"Truck Appointment Status"} : null,
    {"id":10,"isVisible":true,"name":"Temperature"},
    {"id":11,"isVisible":true,"name":"High Value"},
    environment.containerReleaseEnabled ? {"id":12,"isVisible":true,"name":"Container Release Status"} : null
  ];
  defaultToGridIcon: GridIconSetting[] = [
    {"id":1,"isVisible":true,"name":"Margin (Cost, Revenue)"},
    {"id":2,"isVisible":true,"name":"Temperature"},
    {"id":3,"isVisible":true,"name":"High Value"},
    {"id":4,"isVisible":true,"name":"ADR"},
    {"id":5,"isVisible":true,"name":"Conflicts"},
    {"id":6,"isVisible":true,"name":"Remarks"},
    {"id":7,"isVisible":true,"name":"Notifications"},
    {"id":8,"isVisible":true,"name":"TO Send Status"},
    {"id":9,"isVisible":true,"name":"Error on Bc"}
  ];

  selectedBackground;
  landingPageSetting: UserSettings;
  pixelsPerPBSetting: UserSettings;
  weekPlanningUserSetting: UserSettings;
  generalUserSetting: UserSettings;
  planningUserSetting: UserSettings;
  timeline = {
    value : false 
  } ;

  colllpasedPbs = {
    value : false 
  }
  timeLineSetting : UserSettings ;

  changeSequence = {
    value : false 
  } ;
  sequenceSettings : UserSettings ;


  collpasedSetting: UserSettings ; 
  public selectedPlanningGroups: any = [];

  constructor(
    private translate: TranslateService,
    private settingActions: settingAction,
    private actions: SharedActions,
    private readonly toastr: ToastrService,
    private userActions: ApplicationUserActions,
    private readonly http : HttpClientService,
    private readonly userSettingsService: UserSettingsService
  ) {}

  ngOnInit() {
    this.applicationUser$.subscribe((applicationUser: ApplicationUser) => {
      if (applicationUser) {
        this.enableAutocomplete = applicationUser.generalSetting.enableAutocomplete;
        this.applicationUser = applicationUser;
        this.pixelsPerPlanningBlock = this.applicationUser.pixelsPerPlanningBlock;
        this.weekPlanningSetting = this.applicationUser.weekPlanningSetting ? this.applicationUser.weekPlanningSetting : this.weekPlanningSetting;
        this.planningSetting = this.applicationUser.planningSetting ? this.applicationUser.planningSetting : this.planningSetting;

        this.currentGridIcon = this.getGridIcon(this.defaultPBGridIcon, this.applicationUser.defaultGridIcon);
        this.toIcons = this.getGridIcon(this.defaultToGridIcon, this.applicationUser.toIcons);
        this.depotInGridIcons = this.getGridIcon(this.defaultDepotInGridIcons, this.applicationUser.depotInGridIcons);
        this.depotOutGridIcons = this.getGridIcon(this.defaultDepotOutGridIcons, this.applicationUser.depotOutGridIcons);
        this.pixelsPerPlanningBlock = this.applicationUser.pixelsPerPlanningBlock;
        this.weekPlanningSetting = this.applicationUser.weekPlanningSetting ? this.applicationUser.weekPlanningSetting : this.weekPlanningSetting;
        this.planningSetting = this.applicationUser.planningSetting ? this.applicationUser.planningSetting : this.planningSetting;
        this.generalSetting = this.applicationUser.generalSetting ? this.applicationUser.generalSetting : this.generalSetting;
        this.selectedPlanningGroups = this.planningGroups.filter((x) => {
          if(!this.generalSetting.defaultPlanningGroupes)
          return false;
          return this.generalSetting.defaultPlanningGroupes.indexOf(x.id) !== -1;
        });
      }
    });

    this.planningGroups$.subscribe(
      (planningGroups: IdNameAsStringObject[]) => {
        this.planningGroups = planningGroups;
        this.selectedPlanningGroups = this.planningGroups.filter((x) => {
          if(!this.generalSetting.defaultPlanningGroupes)
          return false;
          return this.generalSetting.defaultPlanningGroupes.indexOf(x.id) !== -1;
        });
      }
    );
    this.planningZone$.subscribe((planningZones: any) => {
        if (planningZones) {
            this.planningZones = planningZones;
            this.planningZones = this.planningZones.map(x => x.id);
        }
    });
    this.globalSettings$.subscribe(result => {
      if(result.length){
        this.getLandingPageSetting(result);
        this.getPixelsPerPlanningBlock(result);
        this.getWeekPlanningSettings(result);
        this.getGeneralSettings(result);
        this.getPlanningSetting(result);
        this.getTimelineSetting(result);
        this.getCollapsedPbsSetting(result);
      }
    });
  }

  getLandingPageSetting(settings: UserSettings[]) {
    var setting = settings.find(el => el.setting.name == 'landingPage');
    this.selectedBackground = this.backgrounds[0];
    if(setting) {
      this.selectedBackground = this.backgrounds.find(el => el.landingPage === setting.value);
      this.landingPageSetting = setting;
    }
  }

  getCollapsedPbsSetting(settings: UserSettings[]) {
    var setting = settings.find(el => el.setting.name == 'collapsedPbs');
    if(setting) {
      this.collpasedSetting = setting; 
      this.colllpasedPbs.value = setting.value == "true" ? true : false;
    }
  }

  getTimelineSetting(settings: UserSettings[]) {
    var setting = settings.find(el => el.setting.name == 'timeline');
    if(setting) {
      this.timeLineSetting = setting;
        this.timeline.value = setting.value == "true" ? true : false ;
        localStorage.setItem("timeLine" , setting.value);
    }
  }

  getPlanningSetting(settings: UserSettings[]) {
    var setting = settings.find(el => el.setting.name == 'planning');
    if(setting) {
      this.planningUserSetting = setting;
    }
  }

  getGeneralSettings(settings: UserSettings[]) {
    var setting = settings.find(el => el.setting.name == 'generalSettings');
    if(setting) {
      this.generalUserSetting = setting;
    }
  }

  getWeekPlanningSettings(settings: UserSettings[]) {
    var setting = settings.find(el => el.setting.name == 'weekPlanning');
    if(setting) {
      this.weekPlanningUserSetting = setting;
    }
  }

  getPixelsPerPlanningBlock(settings: UserSettings[]) {
    var setting = settings.find(el => el.setting.name == 'pixelsPerPlanningBlock');
    if(setting) {
      this.pixelsPerPBSetting = setting;
    }
  }

  getChangeSequenceSetting(settings: UserSettings[]) {
    var setting = settings.find(el => el.setting.name == 'changeSequence');
    if(setting) {
      this.sequenceSettings = setting;
      this.changeSequence.value = setting.value == "true" ? true : false ;
      localStorage.setItem("changeSequence" , setting.value);
    }
  }

  private closeModal(): void {
    this.modal.nativeElement.click();
  }
  changeTabStatus(val) {
    this.activeTab = val;
  }
  radioChangeHandlar(event: any) {
    this.selectedScreen = event.target.value;
    console.log(this.selectedScreen);
  }
  SelectDocToPrint(event) {
    if (event.target.checked === true) {
      const Document: DocumentToBePrinted = {
        name: event.target.value
      };
      switch (this.selectedScreen) {
        case "Depot-In":
          this.applicationUser.Screens.depotIn.push(Document);
          break;
        case "Depot-Out":
          this.applicationUser.Screens.depotOut.push(Document);
          break;
        case "PB-Screen":
          this.applicationUser.Screens.pbscreen.push(Document);
          break;
        case "PB-Screen-Details":
          this.applicationUser.Screens.pbdetails.push(Document);
          break;
        case "TO-Screen":
          this.applicationUser.Screens.toscreen.push(Document);
          break;
        case "TO-Screen-Details":
          this.applicationUser.Screens.todetails.push(Document);
          break;
        case "Planning-Board":
          this.applicationUser.Screens.planningBoard.push(Document);
          break;
      }
    } else {
      switch (this.selectedScreen) {
        case "Depot-In":
          const index1 = this.applicationUser.Screens.depotIn.indexOf(
            this.applicationUser.Screens.depotIn.filter(
              x => x.name === event.target.value
            )[0]
          );
          if (index1 !== -1) {
            this.applicationUser.Screens.depotIn.slice(0, index1);
            this.applicationUser.Screens.depotIn.splice(index1, 1);
          }
          break;
        case "Depot-Out":
          const index2 = this.applicationUser.Screens.depotOut.indexOf(
            this.applicationUser.Screens.depotOut.filter(
              x => x.name === event.target.value
            )[0]
          );
          if (index2 !== -1) {
            this.applicationUser.Screens.depotOut.slice(0, index2);
            this.applicationUser.Screens.depotOut.splice(index2, 1);
          }
          break;
        case "PB-Screen":
          const index3 = this.applicationUser.Screens.pbscreen.indexOf(
            this.applicationUser.Screens.pbscreen.filter(
              x => x.name === event.target.value
            )[0]
          );
          if (index3 !== -1) {
            this.applicationUser.Screens.pbscreen.slice(0, index3);
            this.applicationUser.Screens.pbscreen.splice(index3, 1);
          }
          break;
        case "PB-Screen-Details":
          const index4 = this.applicationUser.Screens.pbdetails.indexOf(
            this.applicationUser.Screens.pbdetails.filter(
              x => x.name === event.target.value
            )[0]
          );
          if (index4 !== -1) {
            this.applicationUser.Screens.pbdetails.slice(0, index4);
            this.applicationUser.Screens.pbdetails.splice(index4, 1);
          }
          break;
        case "TO-Screen":
          const index5 = this.applicationUser.Screens.toscreen.indexOf(
            this.applicationUser.Screens.toscreen.filter(
              x => x.name === event.target.value
            )[0]
          );
          if (index5 !== -1) {
            this.applicationUser.Screens.toscreen.slice(0, index5);
            this.applicationUser.Screens.toscreen.splice(index5, 1);
          }
          break;
        case "TO-Screen-Details":
          const index6 = this.applicationUser.Screens.todetails.indexOf(
            this.applicationUser.Screens.todetails.filter(
              x => x.name === event.target.value
            )[0]
          );
          if (index6 !== -1) {
            this.applicationUser.Screens.todetails.slice(0, index6);
            this.applicationUser.Screens.todetails.splice(index6, 1);
          }
          break;
        case "Planning-Board":
          const index7 = this.applicationUser.Screens.planningBoard.indexOf(
            this.applicationUser.Screens.planningBoard.filter(
              x => x.name === event.target.value
            )[0]
          );
          if (index7 !== -1) {
            this.applicationUser.Screens.planningBoard.slice(0, index7);
            this.applicationUser.Screens.planningBoard.splice(index7, 1);
            break;
          }
      }
    }
  }
  IsChecked(elementName: string): boolean {
    var disblay = false;
    if (this.selectedScreen === "Depot-In") {
      this.applicationUser.Screens.depotIn.map(x => {
        if (x.name === elementName) {
          disblay = true;
          return;
        }
      });
      return disblay;
    } else if (this.selectedScreen === "Depot-Out") {
      this.applicationUser.Screens.depotOut.map(x => {
        if (x.name === elementName) {
          disblay = true;
          return;
        }
      });
      return disblay;
    } else if (this.selectedScreen === "PB-Screen") {
      this.applicationUser.Screens.pbscreen.map(x => {
        if (x.name === elementName) {
          disblay = true;
          return;
        }
      });
      return disblay;
    } else if (this.selectedScreen === "PB-Screen-Details") {
      this.applicationUser.Screens.pbdetails.map(x => {
        if (x.name === elementName) {
          disblay = true;
          return;
        }
      });
      return disblay;
    } else if (this.selectedScreen === "TO-Screen") {
      this.applicationUser.Screens.toscreen.map(x => {
        if (x.name === elementName) {
          disblay = true;
          return;
        }
      });
      return disblay;
    } else if (this.selectedScreen === "TO-Screen-Details") {
      this.applicationUser.Screens.todetails.map(x => {
        if (x.name === elementName) {
          disblay = true;
          return;
        }
      });
      return disblay;
    } else if (this.selectedScreen === "Planning-Board") {
      this.applicationUser.Screens.planningBoard.map(x => {
        if (x.name === elementName) {
          disblay = true;
          return;
        }
      });
      return disblay;
    }
  }

  handleFileInput(files: FileList) {
    var file = files.item(0);
    if((file.name.endsWith('.jpg') || file.name.endsWith('.jpeg')) && this.checkFileSize(file))
      this.fileToUpload = file;
    else {
      this.fileToUpload = null;
      this.translate
            .get("TOASTR_MESSAGES.ERROR.WRONG_IMAGE_FORMAT")
            .subscribe((res: string) => {
              this.toastr.error(res);
            });
    }
  }

  checkFileSize(file: File) {
    //less than 100 MB - limit on server
    return ((file.size / (1024*1024)) < 100);
  }

  updateCustomImage() {
    const reader = new FileReader();
    reader.readAsDataURL(this.fileToUpload);
    reader.onload = () => {
      this.updateCustomImageOnServer(<string>reader.result)
    };
    reader.onerror = error => this.toastr.error(error.type);
  }

  updateCustomImageOnServer(base64File: string) {
    base64File = base64File.substr(base64File.indexOf(',') + 1);
    this.http.post(environment.hubEndpointAddress + '/home/CustomLandingPage', base64File).subscribe(
      () => {
        var custom = this.backgrounds[this.backgrounds.length - 1];
        this.backgrounds.splice(this.backgrounds.length - 1);
        this.backgrounds.push(custom);
        this.translate
          .get("TOASTR_MESSAGES.SUCCESS.LANDINGPAGE")
          .subscribe((res: string) => {
            this.toastr.success(res);
          });
      }
    );
  }

  savesetting() {
    if (this.activeTab === 1) {
      switch (this.selectedScreen) {
        case "Depot-In":
          this.applicationUser.Screens.depotIn;
          break;
        case "Depot-Out":
          this.applicationUser.Screens.depotOut;
          break;
        case "PB-Screen":
          this.applicationUser.Screens.pbscreen;
          break;
        case "PB-Screen-Details":
          this.applicationUser.Screens.pbdetails;
          break;
        case "TO-Screen":
          this.applicationUser.Screens.toscreen;
          break;
        case "TO-Screen-Details":
          this.applicationUser.Screens.todetails;
          break;
        case "Planning-Board":
          this.applicationUser.Screens.planningBoard;
          break;
      }
      this.settingActions.updateSettings(
        this.applicationUser,
        "globalPrintSetting"
      );
    } else if(this.activeTab === 2) {
      this.applicationUser.defaultGridIcon = [...this.currentGridIcon];
      this.userActions.updateApplicationUser(
        this.applicationUser,
        "defaultGridIcon" ,
        true
      );
      this.applicationUser.toIcons = [...this.toIcons];
      this.userActions.updateApplicationUser(
        this.applicationUser,
        "toIcons" ,
        true
      );
      this.applicationUser.depotInGridIcons = [...this.depotInGridIcons]; 
      this.applicationUser.depotOutGridIcons = [...this.depotOutGridIcons];
      this.userActions.updateApplicationUser(this.applicationUser,"depotInGridIcons",true);
      this.userActions.updateApplicationUser(this.applicationUser,"depotOutGridIcons",true);
    } else if(this.activeTab === 3) {
      this.setLandingPage();
    } else if(this.activeTab === 4) {
      this.setPixelsPerPB();
      this.saveWeekPlanningSettings();
    }
    else if (this.activeTab === 5)
    {
      this.saveTimeLineToggleSettings();
    }
    else if(this.activeTab === 6)
    {
      this.saveClosedPbsCollapse(); 
        this.savePlanningSettings();
        this.saveChangePbSequence();
    }
    else if(this.activeTab === 7)
    {
      this.generalSetting.defaultPlanningGroupes = this.selectedPlanningGroups.map(x => x.id).join(",");
      this.saveGeneralSettings(); 
    }
  }

  saveChangePbSequence() {
    localStorage.setItem("changeSequence" , JSON.stringify(this.changeSequence.value)); 
    this.setGlobalSetting(this.sequenceSettings, 'changeSequence', JSON.stringify(this.changeSequence.value),
    "Change Planning Blocks Sequence setting updated", (data) => this.sequenceSettings = data);
  }

  saveGeneralSettings() {
    this.setGlobalSetting(this.generalUserSetting, 'generalSettings', JSON.stringify(this.generalSetting),
      "TOASTR_MESSAGES.SUCCESS.GLOBAL_SETTINGS", (data) => {
        this.generalUserSetting = data;
        this.applicationUser.generalSetting = this.generalSetting;
        this.userActions.updateApplicationUserSucceded(this.applicationUser, true);
      });
  }

  saveClosedPbsCollapse() {
    localStorage.setItem("collapsedPbs" , JSON.stringify(this.colllpasedPbs.value)); 
    this.setGlobalSetting(this.collpasedSetting, 'collapsedPbs', JSON.stringify(this.colllpasedPbs.value),
    "Collapsed Closed Planning Blocks setting updated", (data) => this.collpasedSetting = data);
  }

  saveTimeLineToggleSettings() 
  {
    localStorage.setItem("timeLine" , JSON.stringify(this.timeline.value));
    this.setGlobalSetting(this.timeLineSetting, 'timeline', JSON.stringify(this.timeline.value),
    "Timeline toggle setting updated", (data) => this.timeLineSetting = data);
  }
  saveWeekPlanningSettings() {
    this.setGlobalSetting(this.weekPlanningUserSetting, 'weekPlanning', JSON.stringify(this.weekPlanningSetting),
      "TOASTR_MESSAGES.SUCCESS.WEEKPLANNING", (data) => {
        this.weekPlanningUserSetting = data;
        this.applicationUser.weekPlanningSetting = this.weekPlanningSetting;
        this.userActions.updateApplicationUserSucceded(this.applicationUser, true);
      });
  }
  savePlanningSettings() {
    this.setGlobalSetting(this.planningUserSetting, 'planning', JSON.stringify(this.planningSetting),
      "TOASTR_MESSAGES.SUCCESS.PLANNING", (data) => {
        this.planningUserSetting = data;
        this.applicationUser.planningSetting = this.planningSetting;
        this.userActions.updateApplicationUserSucceded(this.applicationUser, true);
      });
  }

  setPixelsPerPB() {
    this.setGlobalSetting(this.pixelsPerPBSetting, 'pixelsPerPlanningBlock', this.pixelsPerPlanningBlock.toString(),
      "TOASTR_MESSAGES.SUCCESS.PIXELS_PER_PB", (data) => {
        this.pixelsPerPBSetting = data;
        this.applicationUser.pixelsPerPlanningBlock = this.pixelsPerPlanningBlock;
        this.userActions.updateApplicationUserSucceded(this.applicationUser, true);
      });
  }
  setLandingPage() {
    this.setGlobalSetting(this.landingPageSetting, 'landingPage', this.selectedBackground.landingPage,
      "TOASTR_MESSAGES.SUCCESS.LANDINGPAGE", (data) => this.landingPageSetting = data);
  }

  setGlobalSetting(setting: UserSettings, settingName: string, value: string, toastrMessage: string, setSetting){
    if(setting) {
      setting.value = value;
      this.userSettingsService.updateUserSettings(setting).subscribe(
        (result) => {
          setSetting(result.data);
          this.translate
            .get(toastrMessage)
            .subscribe((res: string) => {
              this.toastr.success(res);
            });
        }
      );
    }
    else {
      this.userSettingsService.getSettingByName(settingName).subscribe((setting) => {
        this.userSettingsService.createUserSettings(null, setting, value, null, null).subscribe(
          (settings) => {
            setSetting(settings);
            this.translate
              .get(toastrMessage)
              .subscribe((res: string) => {
                this.toastr.success(res);
              });
          }
        );
      });
    }
  }

  saveAndClose() {
    this.savesetting(); 
    this.closeModal();
  }

  getGridIcon(defaultGridIcon: GridIconSetting[], icons: GridIconSetting[]): GridIconSetting[] {
    let userGridSettings = [...defaultGridIcon];
    if(icons)
      icons.forEach(el => {
        var userSetting = userGridSettings.find(us => us && el && us.id == el.id);
        if(userSetting != null)
          userSetting.isVisible = el.isVisible;
      });
    return userGridSettings;
  }
}
