export const environment = {
    envName: 'McCann-live-AZURE',
    production: true,
    tenant: 'Go4Tms4McCannAzure.onmicrosoft.com',
    clientId: '7dfb0841-01d9-4207-aaa5-33d8368c3fb9',

    authority: 'https://login.microsoftonline.com/Go4Tms4McCannAzure.onmicrosoft.com',
    redirectUrl: 'https://mccann-live-pt4-ext.go4tms.net/',

    extApplicationEndpointAddress: 'https://mccann-live-pt4-ext.go4tms.net/',
    tmsApiEndpointAddress: 'https://mccann-live-pt4-tms-api.go4tms.net/tms/v1/',
    hubEndpointAddress: 'https://mccann-live-pt4.go4tms.net',
    tmsUserApiEndPointAddress: 'https://mccann-live-pt4-user-api.go4tms.net/tms/v1/',
    pt4ClientEndPointAddress: 'https://mccann-live-pt4.go4tms.net/#/go4tms/',
    containerPlanningEndpoint: 'https://mccann-live-cp.go4tms.net/',
    defaultPlanningGroup : 'ALL',
    defaultPlanningZone : '',
    defaultPlanDaysAhead : 8,
    defaultPlanDaysBack : 3,
    disableToSelectionModal: false,
    depotInIcon : false , 
    depotOutIcon : false,
    iconType : "truck",
    maxDays : 3 ,
    collectionIcon: true,
    deliveryIcon: true,
    TODaysAhead : 60,
    TOdDaysBack : 60,
    shipping : true , 
    railway : false,
    tarTasEnabled: false,
    withQuotes: false,
    adminGroup : "PTADMINISTRATORS",
    containerReleaseEnabled: false,
    containerPoolEnabled: false,
    buisnessCentralUrl: 'https://mccann-bc.go4tms.net/live/', 
    showWaitingRoom : false ,
    ContainerPlanningApiUri : "", 
    ContainerPlanningClientUri : "" ,
    ContainerPlanningSignalRUri: "",
    MonitorAuthorizedUsers : "",
    checkOverlappingTos: false,
    app_base_href:'/'
  

    
  };

