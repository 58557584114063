import { Component, Input, OnChanges } from '@angular/core';
import {
    CREATED, CANCELED, IN_PROGRESS, ON_HOLD, PLANNED, PRE_PLANNED, RE_USED, TO_BE_PLANNED, CLOSED, ACCEPTED, LINE_PLANNED, LINE_CREATED,
    LINE_IN_PROGRESS, LINE_CLOSED, LINE_CANCELED, LINE_PREPLANNED
} from '../../../models/status-type.interface';
// tslint:disable-next-line:max-line-length

@Component({
    moduleId: module.id,
    selector: 'status-badge',
    templateUrl: './status-badge.component.html',
})
export class StatusBadgeComponent implements OnChanges {
    @Input() public statusType;

    public labelName: string;
    public tagStyle: string;

    public ngOnChanges(): void {
        this.getStatusString();
    }

    public getStatusString(): void {
        switch (this.statusType) {
            case CREATED:
            case LINE_CREATED:
                this.labelName = 'created'; this.tagStyle = 'badge badge-created';
                break;
            case TO_BE_PLANNED:
                this.labelName = 'to be planned'; this.tagStyle = 'badge badge-to-be-planned';
                break;
            case PLANNED:
            case LINE_PLANNED:
                this.labelName = 'planned'; this.tagStyle = 'badge badge-planned';
                break;

            case IN_PROGRESS:
            case LINE_IN_PROGRESS:
                this.labelName = 'in progress'; this.tagStyle = 'badge badge-in-progress';
                break;
            case PRE_PLANNED:
                this.labelName = 'pre planned'; this.tagStyle = 'badge badge-preplanned';
                break;
            case ON_HOLD:
                this.labelName = 'on hold'; this.tagStyle = 'badge badge-danger';
                break;
            case CANCELED:
            case LINE_CANCELED:
                this.labelName = 'canceled'; this.tagStyle = 'badge badge-danger';
                break;

            case RE_USED:
                this.labelName = 'reused'; this.tagStyle = 'badge badge-reused';
                break;
            case CLOSED:
            case LINE_CLOSED:
                this.labelName = 'closed'; this.tagStyle = 'badge badge-done';
                break;
            case ACCEPTED:
                this.labelName = 'accepted'; this.tagStyle = 'badge badge-to-be-planned';
                break;
            case LINE_PREPLANNED:
                this.labelName = 'pre planned'; this.tagStyle = 'badge badge-preplanned';
                break;

            default:
                this.labelName = 'undefined status'; this.tagStyle = 'badge badge-danger';
        }
    }
}
