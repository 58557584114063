import { IdNameAsStringObject } from "../pb-screen/models/quick-view/id-name-as-string-object.interface";

export class WeekPlanningSetting{
  defaultDateRange: number;
  drivingTimeHours: number;
  drivingTimeMinutes: number;
  defaultDateFromRange: number = 0;
}

export class PlanningSetting{
  captionLang: string = "ENU";
}

export class GeneralSetting{
  containerReuseEnabled: boolean;
  checkContainerNumber: boolean;
  useRetourRemarks: boolean;
  disableAutomaticDockPaneOpening: boolean;
  useContainerStatuses: boolean;
  defaultPlanningGroupes: string;
  disableTOSelectionModal: boolean;
  showInitialDateInQuickFilter: boolean;
  manageFileReservation: boolean;
  useBoardComputerStatuses: boolean;
  enableAutocomplete: boolean;
  locationRequired: boolean;
  loadingMetersPb: boolean;
  palletPlacesPb: boolean;
  useContainers: boolean;
  useTrailers: boolean;
  useChassis: boolean;
}